import React from 'react';
import '../Style/Info.css';
import { Col, Container, Row } from 'react-bootstrap';
import hero from '../Assests/imgs/hero.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next'; 

AOS.init();

const Info = () => {
  const { t } = useTranslation(); 

  return (
    <div className='Info'>
      <h1>{t('KABAYAN MOVERS')}</h1> 
      <Container>
        <div data-aos="zoom-in-down" data-aos-delay="50" data-aos-duration="1000">
          <Row>
            <Col lg={6} md={12} sm={12} className="order-lg-1 order-last check ">
              <div className='Intro mt-5'>
                <p>
                  <span>{t('why choose kabayan movers?')}</span> 
                  {t('full_paragraph')} 
                </p>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} className="order-lg-2 order-first check">
              <div className='InfoImg mt-5'>
                <img src={hero} alt='Loading' />
              </div>
            </Col>
          </Row>
        </div>
        <div data-aos="zoom-in" data-aos-delay="50" data-aos-duration="2000">
          <div className='InfoButton'>
            <a href="https://wa.me/+923128337788" target="_blank" rel="noopener noreferrer">
              <div className='btn btn '>{t('Details')}</div>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Info;
