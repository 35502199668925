// Define a type for an object
import cardthree from '../Assests/imgs/cardthree.jpg';
import cardtwo from '../Assests/imgs/cardtwo.jpg';
import cardone from '../Assests/imgs/cardone.jpeg';
import cardfour from '../Assests/imgs/cardfour.jpeg';
import cardfive from '../Assests/imgs/cardfive.jpg';
import cardsix from '../Assests/imgs/cardsix.jpeg';


export const myObjects= [
  {
    heading: 'KMP Packaging Meterial',
    title:'We sell all types of Boxes the top quality cartoons boxs other Packaging Meterial.Like a Moving boxes ,Tapes , stretch film ,bubble Roll, cartoon Roll,glass paper ,tarmacol,cloths hangers etc',
    pic: cardone,
  },
  {
    heading: 'KMP Storage Services',
    title: 'Our storage services provide you with a secure and convenient solution for storing your belongings.Our facilities are equipped with state-of-the-art security measures to ensure the safety of your possessions. With flexible storage options and competitive rates',
    pic: cardtwo,
  },
  {
    heading: 'KMP Painting Services',
    title: 'Our painting services guarantee you a skilled artistic touch that transforms your home or office into a stunning work of art. We offer high-quality painting services at competitive prices.',
    pic: cardthree,
  },
  {
    heading: 'KMP Carpenter Services',
    title: 'Our carpenter services offer expert craftsmanship to meet all your woodworking needs. Whether youre looking to enhance your home with custom furniture, renovate your kitchen with beautiful cabinetry, or add unique woodwork to your interior design.',
    pic: cardfour,
  },
  {
    heading:'KMP Cleaning Services',
    title: 'Experience the epitome of cleanliness with our expert services. From top to bottom, we leave no speck of dust behind, ensuring your space is immaculate. Sit back, relax, and let us transform your environment into a haven of freshnes ',
    pic: cardfive,
  },
  {
    heading: 'KMP Trucks Services',
    title: 'Discover our comprehensive truck services, offering a variety of vehicles including Mazda small pickups and more. Whether youre moving or need a truck for rent, weve got you covered. With reliable vehicles and flexible rental options, we make transportation hassle-free.',
    pic: cardsix,
  },
];

