import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Hamburger from 'hamburger-react';
import SideMenu from '../Components/SideMenu';
import '../Style/Navbar.css';
import LanguageSwitcher from './LanguageSwitcher';
import logo from '../Assests/imgs/logo.png';
import 'animate.css';

import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

function ContainerOutsideExample() {
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation(); // Use the useTranslation hook to access the t function

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className='Navbar'>
      <Container>
        <Navbar expand="lg">
          <Container>     
          <Navbar.Brand href="#"> <img src ={logo} alt='sorry' className='animate__animated animate__slideInLeft custom-duration' /> {t('KABAYAN MOVERS')}</Navbar.Brand>
          </Container>
          <LanguageSwitcher />
        </Navbar>
        {showMenu && <SideMenu onClose={toggleMenu} />}
      </Container>
    </div>
  );
}

export default ContainerOutsideExample;
 