import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

// Import your translation files here
import translationEN from './locales/en.json';
import translationAR from './locales/ar.json';

// Initialize i18next
i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN, // English translations
      },
      ar: {
        translation: translationAR, // Arabic translations
      },
    },
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language in case translation not found
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

// Render your React application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
