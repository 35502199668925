import React from 'react'
import Landingpage from './Pages/Landingpage';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="App">
       <Landingpage />
    </div>
  );
}

export default App;
