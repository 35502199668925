import React, { useEffect, useState } from 'react';
import Home from '../Pages/Home';
import '../Style/Landingpage.css';
function Loading() {
  const [number, setNumber] = useState(0);
  const [showHome, setShowHome] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const final = 100;
    if (number < final) {
      const timer = setTimeout(() => {
        setNumber(number + 1);
      }, 18);
      return () => clearTimeout(timer);
    }
    setShowHome(true);
  }, [number]);

  return (
    <div>
      {showHome ? (
        <Home />
      ) : (
        <div className="Loading-Container">
          <div className="Loading-number">
            {number}
            %
          </div>
        </div>
      )}
    </div>
  );
}

export default Loading;
