/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import Hamburger from 'hamburger-react';    
import '../Style/SideMenu.css';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import { Icon } from '@iconify/react';

const phoneNumber = '+971568107652'; // Replace with your phone number

const handlePhoneCall = () => {
  window.location.href = `tel:${phoneNumber}`;
};

function SideMenu({ onClose }) {
  useEffect(() => {
    if (onClose) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [onClose]);

  const handleNavigateToServices = () => {
    const sellingBoxSection = document.getElementById('sellingBoxSection');
    if (sellingBoxSection) {
      sellingBoxSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="SideMenu">
      <Container>
        <Row className='row'>
          <div className="d-flex justify-content-end">
            <Hamburger color="white" toggled={true} toggle={onClose} />
          </div>
          <Col>
            <Navbar expand="lg" className="verticalMenu d-flex justify-content-center">
              <Nav className="flex-column">
                <Nav.Link href="home">Home</Nav.Link>
                <Nav.Link href="#link">Contact</Nav.Link>
                <Nav.Link onClick={handleNavigateToServices}>Services</Nav.Link>
              </Nav>
            </Navbar>
            <div className='Icons d-flex justify-content-center justify-content-around p-5 '>
              <a href='https://www.facebook.com/allen.uy.16100?mibextid=ZbWKwL'><Icon icon="line-md:facebook" color='#1877F2' /></a>
              <a href='https://www.instagram.com/kabayan_movers_in_abudhabi_?utm_source=qr&igsh=cWM1d2hmZDBqZWhu'><Icon icon="line-md:instagram" color='#f09433' /></a>
              <a href="https://wa.me/971568107652" target="_blank" rel="noopener noreferrer">
                <Icon icon="logos:whatsapp-icon"  />
              </a>
              <a href="#" onClick={handlePhoneCall}>
                <Icon icon="line-md:phone-twotone" color="black" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SideMenu;
