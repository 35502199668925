import React from 'react'
import Navbar from '../Components/Navbar'
import Hero from '../Components/Hero'
import Info from '../Components/Info'
import SellingBoxes from '../Components/SellingBoxes'
import Footer from '../Components/Footer'
import smoothscroll from 'smoothscroll-polyfill';
import ExtraSection from '../Components/ExtraSection';
import Form from '../Components/Form';
smoothscroll.polyfill();




const Home = () => {
  return (
    <div>
    <Navbar />
    <Hero />
    <Info />
    <SellingBoxes />
    <ExtraSection />
    <Form />
<Footer />

    </div>
  )
}

export default Home