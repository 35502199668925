import React from 'react';
import '../Style/ExtraSection.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Row, Container, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
AOS.init();

const SectionWithBackground = () => {
  const { t } = useTranslation();
  return (
    <div className='sectionWithBackground' data-aos="zoom-in-down" data-aos-delay="50" data-aos-duration="2000">
      <h1>{t('Contact Us')}</h1>
      <Container>
        <Row>
          {/* First Column (Contact Information) */}
          <Col lg={4} md={6}>
            <div className='iconHead d-flex' data-aos="zoom-in-down" data-aos-delay="55" data-aos-duration="2000">
              <Icon icon="ion:location-sharp" />
              <p>{t('Abuddhabi Airport Road')}</p>
            </div>
          </Col>
          {/* Second Column */}
          <Col lg={4} md={6}>
            <div className='iconHead d-flex' data-aos="zoom-in-down" data-aos-delay="60" data-aos-duration="2000">
              <Icon icon="entypo:email" />
              <p>Kabayanmovers0@gmail.com</p>
            </div>
          </Col>
          {/* Third Column with AOS effect */}
          <Col lg={4} md={6} data-aos="zoom-in-down" data-aos-delay="62" data-aos-duration="2000">
            <div className='iconHead d-flex'>
              <Icon icon="fluent:call-24-filled" />
              <p>+971568107652</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionWithBackground;
