import React from 'react';
import '../Style/Hero.css';
import 'animate.css';
import hero from '../Assests/imgs/hero1.jpeg';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';



function HeroContainer() {
  const { t } = useTranslation();
  return (
    <div className="HeroContainer">
      <Container>
        <Row>
        <Col lg={6} md={12} sm={12}  className="order-lg-1 order-md-1">
        <div className="HeroHeading mt-5">
          <div className="animate__animated animate__slideInLeft  heroHeading">
          {t('KABAYAN')} 
            <span>
              {' '}
              <i>{t('Movers & Packers')}</i>
            </span>
            <span> {t('in Abudhabi')}</span>
          </div>
        </div>
      </Col>
          <Col lg={6} md={12} sm={12} className="order-lg-2 order-md-2">
            <div className='HeroImage'>
               <img src={hero} alt="Loading" className=' imgHero w-100 '/>
            </div>
          </Col>
        </Row>
        <div className='heroButtton mt-5'>
        <a href="https://wa.me/971568107652" target="_blank" rel="noopener noreferrer">
        <div className='btn btn '>{t('Contact Us')} </div>
      </a></div>
  
      </Container>
    </div>
  );
}

export default HeroContainer;
