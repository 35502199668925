/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import '../Style/Footer.css';
import { useTranslation } from 'react-i18next'; 
import qr from '../Assests/imgs/qrcode.jpg'


const phoneNumber = '+971568107652'; 

const handlePhoneCall = () => {
  window.location.href = `tel:${phoneNumber}`;
};

const Footer = () => {
  const jump = () => {
    document.getElementById('servicesSection').scrollIntoView({ behavior: 'smooth' });
  };
  const { t } = useTranslation(); 
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={4}  className="mb-4">
            <div className="">
              <h1 className="footer-heading text-center">{t('KABAYAN MOVERS')}</h1>
            </div>
          </Col>
          <Col md={4}  className="mb-4">
            <Navbar className= 'flex-column verticalMenu d-flex justify-content-start'>
              <h2 className="footer-heading">{t('Our Services')}</h2>
              <Nav className="flex-column">
                <Nav.Link  onClick={jump}>{t('KMP Packaging Meterial')}</Nav.Link>
                <Nav.Link onClick={jump}>{t('KMP Storage Services')}</Nav.Link>
                <Nav.Link onClick={jump}>{t('KMP Painting Services')}</Nav.Link>
                <Nav.Link onClick={jump}>{t('KMP Carpenter Services')}</Nav.Link>
                <Nav.Link onClick={jump}>{t('KMP Cleaning Services')}</Nav.Link>
              </Nav>
            </Navbar>
          </Col>
          <Col md={4}>
            <div className="footer-section links">
              <h2 className="footer-heading text-center">{t('Contact Us')}</h2>
              <div className='Icons d-flex justify-content-center justify-content-around p-2'>
                <a href='https://www.facebook.com/allen.uy.16100?mibextid=ZbWKwL'><Icon icon="line-md:facebook" color='#1877F2' /></a>
                <a href='https://www.instagram.com/kabayan_movers_in_abudhabi_?utm_source=qr&igsh=cWM1d2hmZDBqZWhu'><Icon icon="line-md:instagram" color='#f09433' /></a>
                <a href="https://wa.me/971568107652" target="_blank" rel="noopener noreferrer">
                  <Icon icon="logos:whatsapp-icon" />
                </a>
                <a href="#" onClick={handlePhoneCall}>
                  <Icon icon="line-md:phone-twotone" color="black" />
                </a>
              </div>
              <div className='qrcode'>
              <img src={qr} alt="Loading"/>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
