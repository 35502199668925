/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../Style/SellingBoxes.css';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { myObjects } from './obj';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next'; 

const phoneNumber = '+971568107652';

const handlePhoneCall = () => {
  window.location.href = `tel:${phoneNumber}`;
};

const generateCardElements = (objects, t) => {



  return objects.map((update, index) => (
    <Col key={index} lg={4} md={6} sm={12} className='mt-5' data-aos="zoom-in" data-aos-delay={50 * (index + 1)} data-aos-duration="1000">
      <Card className="card-zoom">
        <Card.Img variant="top" src={update.pic} alt="sorry" style={{ height: '250px', objectFit: 'cover' }} />
        <Card.Body>
          <Card.Title>
            <text>{t(update.heading)}</text>
          </Card.Title>
          <Card.Text>
            <div className="">
              {t(update.title)}
            </div>
          </Card.Text>
          <div className='InfoButton mt-3 p-0 d-flex justify-content-around'>
            <a href="https://wa.me/+971568107652" target="_blank" rel="noopener noreferrer">
              <Icon icon="logos:whatsapp-icon"  />
            </a>
            <a href='https://www.facebook.com/allen.uy.16100?mibextid=ZbWKwL'> 
              <Icon icon="line-md:facebook" color='#1877F2'  />
            </a>
            <a href="#" onClick={handlePhoneCall}>
              <Icon icon="line-md:phone-twotone"  color="black" />
            </a>
          </div>
        </Card.Body>
      </Card>
    </Col>
  ));
};

const SellingBoxes = () => {
  const { t } = useTranslation();
  return (
    <div className='SellingBoxes' id="servicesSection">
      <h1>{t('Our Services')}</h1>
      <Container >
        <div data-aos="zoom-in" data-aos-delay="50" data-aos-duration="1000">
          <p>{t('Explore our comprehensive range of services tailored to various cities across the UAE. From the bustling streets of Dubai to the serene landscapes of Fujairah, we offer top-notch services in Abu Dhabi, Dubai, Ras Al Khaimah, Al Ain, Fujairah, Ruwais, Madinat Zayed, Sharjah, Ajman, and beyond. Whether youre in need of transportation, logistics, or any other service, we have got you covered. With our extensive network, we ensure reliable and efficient solutions for all your needs, no matter where you are in the UAE.')}</p>
        </div>

        <Row>
          {generateCardElements(myObjects, t)}
        </Row>
      </Container>
    </div>
  )
}

export default SellingBoxes;
